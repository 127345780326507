import React from "react"; 
import { Header } from "../Header/Header"; 
import { Footer } from "../Footer/Footer"; 
import aiImage from '../../imgs/ai.png'; 
import cl1Image from '../../clients/bites.png';
import climage from '../../clients/idlish.png'; 
import cl2image from '../../clients/madhu.png';
import cl3image from '../../clients/logopp.png';
import cl4image from '../../clients/itbridgev7.png';
import cl5image from '../../clients/client3.png';
import DFimage from '../../clients/dsakilogo.png';
import mwimage from '../../clients/5millionwomen.png';
import Ctfimage from '../../clients/chittenadutiffun.png';

export const Home = () => { return <div>
    <Header />

    <div class="bg-homepage1"></div>

    <div className="main">
        <div className="main-content">
            <div class="page-content" id="app">
                <section class="section-box">
                    <div class="banner-hero hero-1">
                        <div class="banner-inner">
                            <div class="row">
                                <div class="col-xl-8 col-lg-12">
                                    <div class="block-banner">
                                        <h1 class="heading-banner wow animate__animated animate__fadeInUp">
                                                Website <span class="color-brand-2">Development</span> Services
                                            </h1>
                                        <div class="banner-description mt-20 wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                                            At our it solution services company, we are dedicated to empowering businesses on their journey of digital transformation. With cutting-edge technologies and a team of skilled professionals.
                                        </div>

                                        <div class="list-tags-banner mt-60 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                            <strong>Popular Searches:</strong>
                                            <a href="">Design</a>,
                                            <a href="">Development</a>,

                                        </div>

                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-12 d-none d-xl-block col-md-6">
                                    <div class="banner-imgs">
                                        <div class="block-1 shape-1">
                                            <img src={aiImage} alt="AI Illustration" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="section-box mt-50 job-of-the-day">
                    <div class="container">
                        <div class="text-center">
                            <h2 class="section-title mb-10 wow animate__animated animate__fadeInUp">Our Services</h2></div>
                        <div class="mt-70">
                            <div class="tab-content" id="myTabContent-1">
                                <div class="tab-pane fade show active" id="tab-job-1" aria-labelledby="tab-job-1">
                                    <div class="row job-of-the-day-list">
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div class="card-grid-2 hover-up items featured-job-item">
                                                <div class="card-grid-2-image-left job-item"><span class="flash"></span></div>
                                                <div class="card-block-info">
                                                    <div class="h6 fw-bold text-truncate"><a href="" title="Website design">Website redesign</a></div>
                                                    <p class="font-sm color-text-paragraph job-description mt-15" title="Website design
 Salary: 1800
 Qualification: any degree &amp; better communication skills in English 
 or any other European&nbsp;languages">Website redesign services involve revamping an existing website to improve its overall design, functionality, and user experience. .</p>
                                                    <div class="mt-15"></div>
                                                    <div class="card-2-bottom mt-15"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div class="card-grid-2 hover-up items featured-job-item">
                                                <div class="card-grid-2-image-left job-item"><span class="flash"></span></div>
                                                <div class="card-block-info">
                                                    <div class="h6 fw-bold text-truncate"><a href="" title="SEO CONTENT WRITING">Seo Content Writting</a></div>
                                                    <p class="font-sm color-text-paragraph job-description mt-15" title="SEO CONTENT WRITING">Proper and effective search engine optimization helps in gaining more customer visits to your website. To increase website traffic,</p>
                                                    <div class="mt-15"></div>
                                                    <div class="card-2-bottom mt-15"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div class="card-grid-2 hover-up items featured-job-item">
                                                <div class="card-grid-2-image-left job-item"><span class="flash"></span></div>
                                                <div class="card-block-info">
                                                    <div class="h6 fw-bold text-truncate"><a href="" title="SOCIAL MEDIA MARKETING COMPANY">SOCIAL MEDIA MARKETING</a></div>
                                                    <p class="font-sm color-text-paragraph job-description mt-15" title="SOCIAL MEDIA MARKETING COMPANY IN CHENNAI
 ">Social media marketing plays a major role in the development of both B2B and B2C businesses.</p>
                                                    <div class="mt-15"></div>
                                                    <div class="card-2-bottom mt-15"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div class="card-grid-2 hover-up items featured-job-item">
                                                <div class="card-grid-2-image-left job-item"><span class="flash"></span></div>
                                                <div class="card-block-info">
                                                    <div class="h6 fw-bold text-truncate"><a href="" title="Website design">Website design</a></div>
                                                    <p class="font-sm color-text-paragraph job-description mt-15" title="DOMAIN AND HOSTING">A domain is the unique address that people type into their web browsers to visit your website. It typically consists of a name (e.g., example) and a domain extension (e.g., .com, .org, .net). The purpose
                                                        of a domain is to provide an easy-to-remember and identifiable name for your website.</p>
                                                    <div class="mt-15"></div>
                                                    <div class="card-2-bottom mt-15"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div class="card-grid-2 hover-up items featured-job-item">
                                                <div class="card-grid-2-image-left job-item"><span class="flash"></span></div>
                                                <div class="card-block-info">
                                                    <div class="h6 fw-bold text-truncate"><a href="" title="Logo & Branding">Logo & Branding</a></div>
                                                    <p class="font-sm color-text-paragraph job-description mt-15" title="Logo & Branding">We understand the importance of branding and we work with our clients to develop a brand identity.</p>
                                                    <div class="mt-15"></div>
                                                    <div class="card-2-bottom mt-15"></div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div class="card-grid-2 hover-up items featured-job-item">
                                                <div class="card-grid-2-image-left job-item"><span class="flash"></span></div>
                                                <div class="card-block-info">
                                                    <div class="h6 fw-bold text-truncate"><a href="" title="Brochures & Flyers">Brochures & Flyers</a></div>
                                                    <p class="font-sm color-text-paragraph job-description mt-15" title="Brochures & Flyers">We create intensely beautiful designs and come up with creative brochure designs to attract your audience.</p>
                                                    <div class="mt-15"></div>
                                                    <div class="card-2-bottom mt-15"></div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="section-box mt-50 top-companies">
                    <div class="container">
                        <div class="text-center">
                            <h2 class="section-title mb-10 wow animate__animated animate__fadeInUp">Our Clients
                                </h2>

                        </div>
                    </div>
                    <div class="container">
                        <div class="box-swiper mt-50">
                            <div class="swiper-container  swiper-style-2 swiper">
                                <div class="swiper-wrapper pt-5">
                                    <div class="swiper-slide">

                                        <div class="item-5 hover-up wow animate__animated animate__fadeIn">
                                            <div class="item-logo">
                                               
                                                    <div class="image-left">
                                                        <img src={cl5image} alt="AI Illustration" />
                                                    </div>
                                                    <div class="text-info-right">
                                                        <h4>GreyLine Brand Work</h4>

                                                    </div>
                                              
                                            </div>
                                        </div>


                                        <div class="item-5 hover-up wow animate__animated animate__fadeIn">
                                            <div class="item-logo">
                                               
                                                    <div class="image-left">
                                                        <img src={cl1Image} alt="AI Illustration" />
                                                    </div>
                                                    <div class="text-info-right">
                                                        <h4>BitesnGrill</h4>



                                                    </div>
                                              
                                            </div>
                                        </div>
                                        <div class="item-5 hover-up wow animate__animated animate__fadeIn">
                                            <div class="item-logo">
                                              
                                                    <div class="image-left">
                                                        <img src={climage} alt="AI Illustration" />
                                                    </div>
                                                    <div class="text-info-right">
                                                        <h4>Idlish</h4>



                                                    </div>
                                               
                                            </div>
                                        </div>


                                         {/* <div class="item-5 hover-up wow animate__animated animate__fadeIn">
                                            <div class="item-logo">

                                                <div class="image-left">
                                                    <img src={cl4image} alt="AI Illustration" />
                                                </div>
                                                <div class="text-info-right">
                                                    <h4>IBC Limited</h4>


                                                </div>

                                            </div>
                                        </div>  */}



                                        <div class="item-5 hover-up wow animate__animated animate__fadeIn">
                                            <div class="item-logo">
                                               
                                                    <div class="image-left">
                                                        <img src={cl3image} alt="AI Illustration" />
                                                    </div>
                                                    <div class="text-info-right">
                                                        <h4>Pretty Petals</h4>


                                                    </div>
                                               

                                            </div>
                                        </div>


                                        <div class="item-5 hover-up wow animate__animated animate__fadeIn">
                                            <div class="item-logo">
                                               
                                                    <div class="image-left">
                                                        <img src={cl2image} alt="AI Illustration" />
                                                    </div>
                                                    <div class="text-info-right">
                                                        <h4>Madhusphonics</h4>



                                                    </div>
                                                

                                            </div>
                                        </div>



                                        <div class="item-5 hover-up wow animate__animated animate__fadeIn">
                                            <div class="item-logo">
                                               
                                                    <div class="image-left">
                                                        <img src={DFimage} alt="AI Illustration" />
                                                    </div>
                                                    <div class="text-info-right">
                                                        <h4>DSAKIFOODS</h4>



                                                    </div>
                                                

                                            </div>
                                        </div>



                                        <div class="item-5 hover-up wow animate__animated animate__fadeIn">
                                            <div class="item-logo">
                                               
                                                    <div class="image-left">
                                                        <img src={Ctfimage} alt="AI Illustration" />
                                                    </div>
                                                    <div class="text-info-right">
                                                        <h4>Chettinad Tiffun</h4>



                                                    </div>
                                                

                                            </div>
                                        </div>


                                        
                                        <div class="item-5 hover-up wow animate__animated animate__fadeIn">
                                            <div class="item-logo">
                                               
                                                    <div class="image-left">
                                                        <img src={mwimage} alt="AI Illustration" />
                                                    </div>
                                                    <div class="text-info-right">
                                                        <h4>5 Million Women</h4>



                                                    </div>
                                                

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </div>
        </div>

    </div>
    <Footer />
</div>
};