import React from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import btImage from '../../projects/project1.jpg';
import btImage2 from '../../projects/project2.jpg';
import btImage3 from '../../projects/project3.jpg';
import btImage4 from '../../projects/project4.jpg';
import btImage5 from '../../projects/project5.jpg';
import btImage6 from '../../projects/project6.jpg';

export const Web = () => {

    return <div>
        <Header />

        <section class="section-box header">
            <div class="breadcrumb-cover bg-img-about breadcrumb-main">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <h2 class="mb-10">Website Design
                            </h2>
                            <p class="font-lg color-text-paragraph-2">Website Design
                            </p>
                        </div>
                        <div class="col-lg-6 text-md-end">
                            <ul class="breadcrumbs  mt-40 ">
                                <li><a href="../index.htm"><span class="fi-rr-home icon-home"></span>Home</a></li>
                                <li>Website Design
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-box mt-50 job-detail-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-12 col-lg-8">
                        <div class="content-single">
                            <div class="ck-content">
                                <h5>AmigoWebster: Elevating Your Digital Presence </h5>
                                <h4>Welcome to AmigoWebster! </h4>
                                <div>
                                    <p>Your one-stop solution for all your website designing needs. We specialize in creating visually stunning and highly functional websites that help you stand out in the digital landscape. Here’s what we offer: </p>
                                    <h4>Our Services: </h4>
                                    <ul>
                                        <li>Custom Website Design </li>
                                        <li>Responsive Web Development </li>
                                        <li>E-commerce Solutions </li>
                                        <li>Content Management Systems (CMS) </li>
                                        <li>SEO Optimization </li>
                                        <li>Website Maintenance and Support </li>
                                    </ul>
                                </div>
                                <h5>Why Choose AmigoWebster? </h5>
                                <div>
                                    <ul>
                                        <li>
                                            <strong>Experienced Team:</strong> Our designers and developers have years of experience in the industry.
                                        </li>
                                        <li>
                                            <strong>Client-Centric Approach: </strong>We prioritize your needs and work closely with you throughout the design process.
                                        </li>
                                        <li>
                                            <strong>Innovative Solutions:</strong> We stay ahead of the curve with the latest trends and technologies.
                                        </li>
                                        <li>
                                            <strong>Affordable Pricing:</strong> High-quality services that won’t break the bank.
                                        </li>
                                    </ul>
                                </div>
                                <h5>Our Process: </h5>
                                <div>
                                    <ul>
                                        <li>Understanding your goals, target audience, and specific requirements. </li>
                                        <li>Creating a detailed project plan, including design concepts and timelines. </li>
                                        <li>Bringing your vision to life with a focus on aesthetics and functionality. </li>
                                        <li>Thorough testing to ensure a flawless user experience, followed by a seamless launch. </li>
                                        <li>Continuous support and maintenance to ensure your website remains at its best. </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                  
 
                        <div class="single-apply-jobs">
                            <div class="row align-items-center">
                                <div class="col-md-5">
                                    <div class="">
                                        <button class="btn btn-default mr-15">
                                            <a href="/Contact"> Contact Us</a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer />
    </div>
};