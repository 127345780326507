import axios from 'axios'
import React, { useState, useEffect } from 'react';
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { Link } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from 'base-64'

const cors = require('cors');


//allow users
const corsoption = {
    origin: ['https://amigowebster.com']
}



let MID = "M22N67J8YMLXF";
let saltKey = "02225ae3-0f39-4c3f-9ea4-f127c961a814";
let saltIndex = "1";


let data = {
    name: "Basic Plan",
    amount: 4000,
    merchantId: MID,
    transactionId: 'T' + Date.now()
}


let data2 = {
    "name": "Standard Plan",
    "amount": 10000,
    "merchantId": "M22N67J8YMLXF",
    "callbackUrl": "http://localhost:3000/Price",
    "transactionId": "T"+ Date.now() 
}


const preprodurl = "https://api.phonepe.com/apis/hermes"
const preprodendpint = "/pg/v1/pay"


const HandleClick2 = async () => {

    try {


        let issuccess = false;
        let hosturl = "http://localhost:8012/payment";
        console.log(hosturl);
        const response = await fetch(hosturl, {
            method: 'POST',
            headers: {
                "accept": 'text/plain',
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data2)
        }).then(res => {

            if (res.status === 200) {
                issuccess = true;

            }

        }).catch(err => {
            console.log(err)
        })

        if (issuccess) {
            var payload = {
                "merchantId": data2.merchantId,
                "merchantTransactionId": data2.transactionId,
                "merchantUserId": "MUID123",
                "amount": data2.amount,
                "redirectUrl": data2.callbackUrl,
                "redirectMode": "REDIRECT",
                "callbackUrl": data2.callbackUrl,
                "mobileNumber": "9999999999",
                "paymentInstrument": {
                    "type": "PAY_PAGE"
                }
            }

            const encodedreq = base64_encode(JSON.stringify(payload));
            var request = {
                "request": encodedreq
            }

            await fetch(preprodurl + preprodendpint, request).then(res => {
                //console.log(res);
            });



        }

    } catch (error) {
        console.log(error)
    }
}



const HandleClick = async () => {

    try {


        const response = await fetch(`${window.location.href}/Price`, data).then(res => {


            console.log(res)
            console.log(data)

            if (res.data === true) {
                window.location.href = res.data.data.instrumentResponse.redirectInfo.url
            }

        }).catch(err => {
            console.log(err)
        })

    } catch (error) {
        console.log(error)
    }



}



export const Price = () => {

    return <div>
        <Header />

        <section class="section-box header">
            <div class="breadcrumb-cover bg-img-about breadcrumb-main">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <h2 class="mb-10">Our Pricing
                            </h2>
                            <p class="font-lg color-text-paragraph-2">Our Pricing </p>
                        </div>
                        <div class="col-lg-6 text-md-end">
                            <ul class="breadcrumbs  mt-40 ">
                                <li><a href="../index.htm"><span class="fi-rr-home icon-home"></span>Home</a></li>
                                <li>Our Pricing
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="section-box ">
            <div class="container">
                <h2 class="text-center mb-15">
                    Our Pricing
                </h2>
                <div class="font-lg color-text-paragraph-2 text-center">
                    Choose The Best Plan That’s For You
                </div><br />
                <div class="max-width-price">
                    <div class="block-pricing">
                        <div class="row">
                            <div class="col-xl-4 col-lg-6 col-md-6">
                                <div class="box-pricing-item">
                                    <h3>Basic</h3>
                                    <div class="box-info-price">
                                        <span class="text-price color-brand-2">4000 ₹</span>
                                    </div>
                                    <div class="border-bottom mb-30"></div>
                                    <ul class="list-package-feature">
                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            4 page Basic Design
                                        </li>

                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            Responsive Website
                                        </li>
                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            Delivery in 2 days
                                        </li>



                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            Live Chat
                                        </li>

                                    </ul>
                                    <div>
                                        <button class="btn btn-default btn-shadow ml-30 hover-up" onClick={HandleClick}>Pay Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6">
                                <div class="box-pricing-item">
                                    <h3>Standard</h3>
                                    <div class="box-info-price">
                                        <span class="text-price color-brand-2">6000 ₹</span>
                                    </div>
                                    <div class="border-bottom mb-30"></div>
                                    <ul class="list-package-feature">

                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            6 Page Design
                                        </li>

                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            Email Upto 5 Accounts
                                        </li>

                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            Domain will be registered for a year
                                        </li>



                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            Responsive Website
                                        </li>
                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            Delivery in 4 days
                                        </li>

                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            SSL will be provided
                                        </li>

                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            Live Chat
                                        </li>



                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            Hosting space will be provided for a year
                                        </li>

                                    </ul>
                                    <div>
                                        <button class="btn btn-default btn-shadow ml-30 hover-up" onClick={() => { HandleClick2() }}>Pay Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6">
                                <div class="box-pricing-item">
                                    <h3>Premium</h3>
                                    <div class="box-info-price">
                                        <span class="text-price color-brand-2">20000 ₹</span>
                                    </div>
                                    <div class="border-bottom mb-30"></div>
                                    <ul class="list-package-feature">
                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            Unlimited Website Page Design
                                        </li>


                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            Email Upto 5 Accounts
                                        </li>

                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            Ecommerce Site will be provided

                                        </li>
                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            Payment Gateway Integration

                                        </li>

                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            Domain will be registered for a year
                                        </li>

                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            Responsive Website
                                        </li>

                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            Faster Delivery
                                        </li>

                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            SSL will be provided
                                        </li>
                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            Live Chat
                                        </li>


                                        <li>
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle opacity="0.1" cx="14" cy="14" r="14" fill="rgb(61, 133, 198)"></circle>
                                                <path d="M19 10.5L11.5 18L8.5 15" stroke="rgb(61, 133, 198)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            Hosting space will be registered for a year
                                        </li>


                                    </ul>
                                    <div>
                                        <button class="btn btn-default btn-shadow ml-30 hover-up" onClick={() => { HandleClick2() }}>Pay Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer />
    </div>
};