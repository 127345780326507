import React from "react";
import { Link } from "react-router-dom";
import logofoo from '../../projects/logo.png';
import logofac from '../../projects/facebook.png';
import logoflin from '../../projects/linkein.png';
import logotwe from '../../projects/twitter.png';

export const Footer = () => {

    return <div>
        <footer class="footer mt-50">
            <div class="container">
                <div class="row">
                    <div class="footer-col-1 col-md-3 col-sm-12">
                        <a href="index.htm" aria-label="Amigowebster - Low cost website designing company">
                            <img src={logofoo} alt="Amigowebster - Low cost website designing company" />
                        </a>
                        <div class="mt-20 mb-20 font-xs color-text-paragraph-2">
                             We at Amigowebster, are more than just a digital marketing and IT services provider, 
                             we can help build your digital presence, by building a functional yet awe-inspiring website for your business.
                        </div>

                        <div class="footer-social">
                            <a class="icon-socials" title="Facebook"
                                href="https://www.facebook.com/profile.php?id=61557282387142" target="_blank">
                                <img src={logofac} alt="Amigowebster - Low cost website designing company" />
                            </a>
                            <a class="icon-socials" title="Linkedin" href="https://www.linkedin.com/company/amigowebster-com/" target="_blank">
                                <img src={logoflin} alt="Amigowebster - Low cost website designing company" />
                            </a>
                            <a class="icon-socials" title="Twitter" href="https://x.com/1988Balaji" target="_blank">
                                <img src={logotwe} alt="Amigowebster - Low cost website designing company" />
                            </a>
                        </div>
                    </div>
                    <div class="footer-col-2 col-md-2 col-xs-6">
                        <div class="h6 mb-20">Resources</div>
                        <ul class="menu-footer">
                            <li><Link to="/About">About</Link></li>

                            <li><Link to="/Portofolio">Portofolio</Link></li>
                            <li><Link to="/Contact">Contact</Link></li>
                            <li><Link to="/Privacypolicy">Privacy policy</Link></li>
                            <li><Link to="/Termsandconditions">Terms and conditions</Link></li>
                            <li><Link to="/Refundpolicy">Refund policy</Link></li>
                        </ul>

                    </div>




                    <div class="footer-col-7 col-md-7 col-xs-7">
                        <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D61557282387142&amp;tabs=timeline&amp;width=340&amp;height=500&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId" width="800" height="400" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

                    </div>


                </div>
                <div class="footer-bottom mt-50">
                    <div class="row">
                        <div class="col-md-6">
                            <span class="font-xs color-text-paragraph">
                                ©2024 Amigowebster. All right reserved.
                            </span>
                        </div>
                        <div class="col-md-6 text-md-end text-start">
                            <div class="footer-social">

                            </div>
                            <div class="nav float-right language-switcher-footer">


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
};

