import React from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import btImage from '../../projects/project1.jpg';
import btImage2 from '../../projects/project2.jpg';
import btImage3 from '../../projects/project3.jpg';
import btImage4 from '../../projects/project4.jpg';
import btImage5 from '../../projects/project5.jpg';
import btImage6 from '../../projects/project6.jpg';
import btImage7 from '../../projects/project7.jpg';
import dsaki from '../../projects/dsaki.jpg'

export const Portfolio = () => {

    return <div>
        <Header />



        <section class="section-box">
            <div class="breadcrumb-cover bg-img-about breadcrumb-main">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <h2 class="mb-10">Our Portfolio</h2>
                            <p class="font-lg color-text-paragraph-2">Our Portfolio</p>
                        </div>
                        <div class="col-lg-6 text-md-end">
                            <ul class="breadcrumbs  mt-40 ">
                                <li><a href="../index.htm"><span class="fi-rr-home icon-home"></span>Home</a></li>
                                <li>Our Portfolio</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="row">
            <div class="container">
                <div class="col-lg-12 text-center">
                    <h2 class="mb-10 headingmobile">Website Design</h2></div>
            </div>
        </div>


        <section class="section pb-100">
            <div class="container">
                <div class="page-content">
                    <article class="post post--single">
                        <div class="post__content">
                            <div id="list-photo">
                                <div class="item" data-src="projects/project5.jpg" data-sub-html="Praesentium velit laudantium iure modi. Dolores nobis enim eaque aut. Et non repudiandae non.">
                                    <div class="photo-item">
                                        <div class="thumb">
                                            <a href="http://greylinebw.com/"><img src={btImage} alt="Amigowebster - Low cost website designing company" /></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="item" data-src="projects/project6.jpg" data-sub-html="Eaque saepe cumque excepturi est sint nisi a reprehenderit. Numquam vel esse eligendi. Aut deserunt architecto autem veritatis facere et ut.">
                                    <div class="photo-item">
                                        <div class="thumb">
                                            <a href="https://bitesngrill.in/"><img src={btImage2} alt="Amigowebster - Low cost website designing company" /></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="item" data-src="projects/project10.jpg" data-sub-html="Tempore occaecati enim est quis. Non sit quas fugiat rerum sit et voluptas. Repellendus et eum est.">
                                    <div class="photo-item">
                                        <div class="thumb">
                                            <a href="https://sribhavanipackersandmovers.com/"><img src={btImage3} alt="Amigowebster - Low cost website designing company" /></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="item" data-src="projects/project11.jpg" data-sub-html="Enim in deserunt et voluptate dignissimos voluptas optio est. Culpa ratione vel nam et. Sint voluptate qui ut quos illo iste ipsum.">
                                    <div class="photo-item">
                                        <div class="thumb">
                                            <a href="https://mothertv.live/"><img src={btImage4} alt="Amigowebster - Low cost website designing company" /></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="item" data-src="projects/project4.jpg" data-sub-html="Pariatur architecto fuga vel aperiam dolorum et quas. Asperiores dolores voluptas modi est quibusdam ut. Illum eos suscipit dolores saepe dolorum et.">
                                    <div class="photo-item">
                                        <div class="thumb">
                                            <a href="https://idlish.shop/"><img src={btImage5} alt="Amigowebster - Low cost website designing company" /></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="item" data-src="projects/project13.jpg" data-sub-html="Sit eligendi quam repellendus. Cupiditate et ducimus qui ut. Mollitia illum error amet ut ullam eveniet. Expedita qui dolorem sed qui.">
                                    <div class="photo-item">
                                        <div class="thumb">
                                            <a href="https://www.madhusphonics.com/"><img src={btImage6} alt="Amigowebster - Low cost website designing company" /></a>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="item" data-src="projects/project7.jpg" data-sub-html="Sit eligendi quam repellendus. Cupiditate et ducimus qui ut. Mollitia illum error amet ut ullam eveniet. Expedita qui dolorem sed qui.">
                                    <div class="photo-item">
                                        <div class="thumb">
                                            <a href="https://www.ibclimited.co.in/"><img src={btImage7} alt="Amigowebster - Low cost website designing company" /></a>
                                        </div>
                                    </div>
                                </div>

                                <div class="item" data-src="projects/project7.jpg" data-sub-html="Sit eligendi quam repellendus. Cupiditate et ducimus qui ut. Mollitia illum error amet ut ullam eveniet. Expedita qui dolorem sed qui.">
                                    <div class="photo-item">
                                        <div class="thumb">
                                            <a href="https://www.dsakifoods.in/"><img src={dsaki} alt="Amigowebster - Low cost website designing company" /></a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <br />
                            <div class="facebook-comment">
                                <div class="fb-comments" data-href="https://Amigowebster.org/galleries/happy-day" data-numposts="5" data-width="100%"></div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </section>

        <Footer />
    </div>
};