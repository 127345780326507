import React, { useState } from 'react';
import LoImage from '../../imgs/logo.png';
import { FaBars, FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Link } from "react-router-dom";

export const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };
  return (
    <nav className="menu-bar header sticky-bar">
      <div className="menu-logo">
         <Link to="/Home"><img className='logoimg' src={LoImage} alt="Amigowebster - Low cost website designing company" /></Link> 
      </div>
      <ul className={isMobile ? "menu-links-mobile" : "menu-links"} >
        <li> <Link to="/Home">Home</Link> </li>
        <li><Link to="/About">About</Link> </li>
        <li className="submenu-item">
          <a onClick={toggleSubMenu}>
            Services {isSubMenuOpen ? <FaChevronUp /> : <FaChevronDown />}
          </a>
          {isSubMenuOpen && (
            <ul className="submenu">
              <li><Link to="/Host">Hosting & Domain</Link></li>
              <li><Link to="/Web">Website Design</Link> </li>
              <li><Link to="/Seo">SEO</Link></li>
            </ul>
          )}
        </li>
        <li><Link to="/Price">Price</Link></li>
        <li><Link to="/Portfolio">Portfolio</Link></li>
        <li><Link to="/Contact">Contact</Link></li>
      </ul>
      <button className="mobile-menu-icon"
              onClick={() => setIsMobile(!isMobile)}>
        {isMobile ? <FaTimes /> : <FaBars />}
      </button>
    </nav>
  );
};

export default Header;
