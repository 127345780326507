import React, { useState , useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Form } from 'semantic-ui-react'
import axios from 'axios';
import { Header } from "../Header/Header";
import {Footer} from "../Footer/Footer";

function Aboutupdate() {
  
    let navigate = useNavigate();
    const [id, setID] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [checkbox, setCheckbox] = useState(false);

    useEffect(() => {
        setID(localStorage.getItem('ID'))
        setFirstName(localStorage.getItem('First Name'));
        setLastName(localStorage.getItem('Last Name'));
        setCheckbox(localStorage.getItem('Checkbox Value'));
    }, []);
    
    const updateAPIData = () => {
        axios.put(`https://60fbca4591156a0017b4c8a7.mockapi.io/fakeData/${id}`, {
            firstName,
            lastName,
            checkbox
        }).then(() => {
            navigate('/Aboutshow')
        })  
        return (
            <div>
                <Header />
                <Form className="create-form">
                    <Form.Field>
                        <label>First Name</label>
                        <input placeholder='First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Last Name</label>
                        <input placeholder='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                    </Form.Field>
                    <Form.Field>
                        <Checkbox label='I agree to the Terms and Conditions' checked={checkbox} onChange={() => setCheckbox(!checkbox)}/>
                    </Form.Field>
                    <Button type='submit' onClick={updateAPIData}>Update</Button>
                </Form>
                <Footer/>
            </div>
        )
    }
}

export default Aboutupdate;