import React from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import btImage from '../../projects/project1.jpg';
import btImage2 from '../../projects/project2.jpg';
import btImage3 from '../../projects/project3.jpg';
import btImage4 from '../../projects/project4.jpg';
import btImage5 from '../../projects/project5.jpg';
import btImage6 from '../../projects/project6.jpg';

export const Host = () => {

    return <div>
        <Header />

        <section class="section-box header">
            <div class="breadcrumb-cover bg-img-about breadcrumb-main">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <h2 class="mb-10">Hosting & Domain
                            </h2>
                            <p class="font-lg color-text-paragraph-2">Hosting & Domain </p>
                        </div>
                        <div class="col-lg-6 text-md-end">
                            <ul class="breadcrumbs  mt-40 ">
                                <li><a href="../index.htm"><span class="fi-rr-home icon-home"></span>Home</a></li>
                                <li>Website Design
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   

        <section class="section-box mt-50 job-detail-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-12 col-lg-8">
                       
                        <div class="content-single">
                            <div class="ck-content">
                                <h5>Hosting Services from AmigoWebster
                                </h5>
   
                                <div>
                          
                                    <h4>1. Shared Hosting
                                    </h4>
                                    <ul>
                                        <li>Affordable and reliable hosting solution for small to medium-sized websites.
                                        </li>
                                        <li>Shared resources with other websites, ensuring cost-effective performance.
                                        </li>
                                        <li>Ideal for personal blogs, small business sites, and portfolios.
                                        </li>
                                        <li>Features include easy-to-use control panel, one-click installations, and 24/7 customer support.
                                                        </li>
                                    </ul>
                                </div>
                                <h5>2. VPS Hosting
                                </h5>
                                <div>
                                    <ul>
                                        <li>Virtual Private Server (VPS) hosting for websites that require more control and resources.
                                        </li>
                                        <li>Provides dedicated resources within a shared environment.
                                        </li>
                                        <li>Ideal for growing businesses, e-commerce sites, and high-traffic websites.
                                        </li>
                                        <li>Features include full root access, scalable resources, and robust security.
                                        </li>
                                      
                                    </ul>
                                </div>

                                <h5>3. Dedicated Hosting
                                </h5>
                                <div>
                                    <ul>
                                        <li>High-performance hosting with a dedicated server exclusively for your website.
                                        </li>
                                        <li>Maximum control, customization, and security.
                                        </li>
                                        <li>Ideal for large businesses, enterprises, and websites with high traffic and resource demands.
                                        </li>
                                        <li>Features include dedicated IP addresses, high-speed SSD storage, and advanced security options.
                                        </li>
                    
                                    </ul>
                                </div>


                                <h5>4. Managed WordPress Hosting

                                </h5>
                                <div>
                                    <ul>
                                        <li>Optimized hosting specifically for WordPress websites.
                                        </li>
                                        <li>Automated updates, backups, and security measures tailored for WordPress.
                                        </li>
                                        <li>Ideal for bloggers, developers, and businesses using WordPress.
                                        </li>
                                        <li>Features include pre-installed WordPress, enhanced speed, and expert WordPress support.
                                        </li>

                                    </ul>
                                </div>


                                <h5>5. Reseller Hosting


                                </h5>
                                <div>
                                    <ul>
                                        <li>Hosting solution for entrepreneurs and businesses looking to offer web hosting services.                                      
                                        </li>
                                        <li>Manage and resell hosting packages under your own brand.
                                        </li>
                                        <li>Ideal for web developers, agencies, and IT service providers.
                                        </li>
                                        <li>Features include white-label branding, customizable plans, and billing management tools.
                                        </li>

                                    </ul>
                                </div>


                                <h5>6. Domain Registration and Management
                                </h5>
                                <div>
                                    <ul>
                                        <li>Easy and affordable domain registration services.
                                        </li>
                                        <li>Manage all your domains in one place with user-friendly tools.
                                        </li>
                                        <li>Features include domain forwarding, WHOIS privacy, and DNS management.
                                        </li>
                                        
                                    </ul>
                                </div>


                                <h5>7. 24/7 Customer Support

                                </h5>
                                <div>
                                    <ul>
                                        <li>Dedicated support team available around the clock.
                                        </li>
                                        <li>Multiple support channels including live chat, phone, and email.
                                        </li>
                                        <li>Knowledgeable and friendly support staff to assist with any hosting-related issues.
                                        </li>

                                    </ul>
                                </div>


                            </div>
                        </div>

               

               


                        <div class="single-apply-jobs">
                            <div class="row align-items-center">
                                <div class="col-md-5">
                                    <div class="">
                                        <button class="btn btn-default mr-15">
                                            <a  href="/Contact"> Contact Us</a>
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </section>

        <Footer />
    </div>
};