import React from "react";
import { Header } from "../Header/Header";
import {Footer} from "../Footer/Footer";
import AbImage from '../../imgs/img-about2.png';

export const About = () => {
    
    return <div className="">
        <Header />
        <section class="section-box header">
            <div class="breadcrumb-cover bg-img-about breadcrumb-main">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <h2 class="mb-10">About Us</h2>
                            <p class="font-lg color-text-paragraph-2">About Us</p>
                        </div>
                        <div class="col-lg-6 text-md-end">
                            <ul class="breadcrumbs  mt-40 ">
                                <li><a href="../index.htm"><span class="fi-rr-home icon-home"></span>Home</a></li>
                                <li>About Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="section-box">

            <div class="ck-content">
            <section class="section-box mt-50">
                <div class="post-loop-grid">
                    <div class="container">
                        <div class="text-center">
                            <h6 class="f-18 color-text-mutted text-uppercase"></h6>
                            <h2 class="section-title mb-10 wow animate__ animate__fadeInUp animated"
                               >
                                About Our Company
                            </h2>
                            <p class="font-sm color-text-paragraph wow animate__ animate__fadeInUp w-lg-50 mx-auto animated ">
                                Welcome to Amigowebster – Your gateway to making an eminent digital presence!
                                We at Amigowebster, are more than just a digital marketing and IT services provider, we can help build your digital presence, by building a functional yet awe-inspiring website for your business. We specialize in crafting exceptional online experiences through cutting-edge website design, robust web and application development, impactful branding, and strategic digital marketing.                                 
                            </p>
                            <br/>
                            <p class="section-title mb-10 wow animate__ animate__fadeInUp animated ">Amigowebster believes that every business deserves a spectacular website that can showcase the brand, its values, and its esteemed services to its target audience and the world. A website acts as a virtual shop that provides your customers and visitors a peek into the image of that business. We can help create, design, and maintain your website that best reflects your products, services, and values effectively. </p>
                        </div>
                        <div class="row mt-70">
                            <div class="col-lg-6 col-md-12 col-sm-12">
                              <img src={AbImage} alt="AI Illustration" />
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12">
                                <h3 class="mt-15">Our USP: Tailor-made Services and Customer Satisfaction</h3>
                                <div class="mt-20">
                                    <p class="font-md color-text-paragraph mt-20 justify">AmigoWebster is a leading and customer-focused web development company in Chennai. With over 8 years of experience in the industry, we have designed and created hundreds of websites for B2B & B2C clients in Chennai & Bangalore. We design custom web pages based on your target audience and business, to effectively market your digital products & services 
                                        in the best possible way that will help you grow & differentiate from your competitors! </p>
                                </div>


                                <h3 class="mt-15">Our Diverse Portfolio</h3>
                                <div class="mt-20">
                                    <p class="font-md color-text-paragraph mt-20 justify">Amigowebster has a diverse and spectacular portfolio of working with many high-end clientele and companies. We have provided top-notch, engaging, and functional websites for clients all over the country, including <strong>Bitesngrill, Madhuphonics, and Grey Line BW.</strong> We treat each website independently as per their niche and strive to provide the best website solutions for your business. 
                                        If you are someone who is looking to upgrade their brand image, and promote your business to your targeted audience, we can help you all the way. Check out some of the winning services that we offer, and get in touch with us to get started today! 
                                        </p>
                                </div>


                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          
     

        </div>


        </section>
        <Footer/>
    </div>
};