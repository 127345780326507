
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

// import {Create} from './Components/Create/Create';
import { Home } from './Components/Home/Home';
import { About } from './Components/About/About';
import { Seo } from './Components/Seo/Seo';
import { Contact } from './Components/Contact/Contact';
import { Web } from "./Components/web/web";
import { Host } from "./Components/host/Host";
import { Price } from "./Components/Price/Price";
import Aboutadd from './Components/Aboutadd/Aboutadd';
import Aboutshow from './Components/Aboutshow/Aboutshow';
import Aboutupdate from './Components/Aboutupdate/Aboutupdate';
import { Privacypolicy } from "./Components/Privacypolicy/Privacypolicy";
import { Termsandconditions } from "./Components/Termsandconditions/Termsandconditions";
import { Refundpolicy } from "./Components/Refundpolicy/Refundpolicy";
import { Portfolio } from "./Components/Portfolio/Portfolio";

function App() {
  return <BrowserRouter>
  <Routes>

      <Route path="/" element={<Home/>} />
      <Route path="/Home" element={<Home />} />
      <Route path="/About" element={<About />} />
      <Route path="/Seo" element={<Seo />} />

      <Route path="/Price" element={<Price />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/Web" element={<Web />} />
      <Route path="/Host" element={<Host />} />
      <Route path="/Aboutshow" element={<Aboutshow />} /> 
      <Route path="/Aboutadd" element={<Aboutadd />} />  
      <Route path="/Aboutupdate" element={<Aboutupdate />} />
      <Route path="/Privacypolicy" element={<Privacypolicy />} />
      <Route path="/Termsandconditions" element={<Termsandconditions />} />
      <Route path="/Refundpolicy" element={<Refundpolicy />} />
      <Route path="/Portfolio" element={<Portfolio />} />
      {/* <Route exact path='/create' component={create} /> */}
      {/* <Route exact path='/Read' component={Read} />
      <Route path='/Update' component={Update} /> */}


  </Routes>
</BrowserRouter>;
}

export default App;
