import React from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import btImage from '../../projects/project1.jpg';
import btImage2 from '../../projects/project2.jpg';
import btImage3 from '../../projects/project3.jpg';
import btImage4 from '../../projects/project4.jpg';
import btImage5 from '../../projects/project5.jpg';
import btImage6 from '../../projects/project6.jpg';

export const Seo = () => {

    return <div>
        <Header />

        <section class="section-box header">
            <div class="breadcrumb-cover bg-img-about breadcrumb-main">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <h2 class="mb-10">Seo</h2>
                            <p class="font-lg color-text-paragraph-2">Seo</p>
                        </div>
                        <div class="col-lg-6 text-md-end">
                            <ul class="breadcrumbs  mt-40 ">
                                <li><a href="../index.htm"><span class="fi-rr-home icon-home"></span>Home</a></li>
                                <li>Seo</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-box mt-50 job-detail-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-12 col-lg-8">
                       
                        <div class="content-single">
                            <div class="ck-content">
                                <h5>SEO Services 
                                </h5>
   
                                <div>
                          
                                    <h4>1. Website Audit and Analysis
                                    </h4>
                                    <ul>
                                        <li>Conduct a comprehensive audit of AmigoWebster's website to identify strengths, weaknesses, and areas of improvement.    
                                        </li>
                                        <li>Analyze current traffic, bounce rates, and conversion rates.
                                        </li>
                                        <li>Evaluate on-page SEO factors such as meta tags, headers, and keyword usage.
                                        </li>
                                    </ul>
                                </div>
                                <h5>2. Keyword Research
                                </h5>
                                <div>
                                    <ul>
                                        <li>Identify relevant and high-performing keywords for AmigoWebster's niche.
                                        </li>
                                        <li>Analyze competitor keywords and search trends.
                                        </li>
                                        <li>Develop a targeted keyword strategy to improve search engine rankings.
                                        </li>
                                      
                                    </ul>
                                </div>

                                <h5>3. On-Page Optimization

                                </h5>
                                <div>
                                    <ul>
                                        <li>Optimize meta tags, titles, and descriptions for targeted keywords.
                                        </li>
                                        <li>Improve website content with keyword-rich headings, subheadings, and body text.
                                        </li>
                                        <li>Ensure proper use of alt text for images.
                                        </li>
                                        <li>Enhance internal linking structure to improve site navigation and user experience.
                                        </li>
                    
                                    </ul>
                                </div>


                                <h5>4. Content Strategy

                                </h5>
                                <div>
                                    <ul>
                                        <li>Develop a content plan that includes blog posts, articles, and other content types focused on targeted keywords.
                                        </li>
                                        <li>Create high-quality, engaging, and informative content to attract and retain visitors.
                                        </li>
                                        <li>Implement a regular publishing schedule to keep content fresh and relevant.
                                        </li>


                                    </ul>
                                </div>


                                <h5>5. Technical SEO


                                </h5>
                                <div>
                                    <ul>
                                        <li>Improve website speed and performance.                                        .
                                        </li>
                                        <li>Ensure mobile-friendliness and responsive design.
                                        </li>
                                        <li>Implement schema markup to enhance search engine understanding of the content.
                                        </li>
                                        <li>Fix broken links, 404 errors, and other technical issues.
                                        </li>

                                    </ul>
                                </div>


                                <h5>6. Local SEO
                                </h5>
                                <div>
                                    <ul>
                                        <li>Optimize website for local search if applicable.                                        .
                                        </li>
                                        <li>Create and optimize Google Client Business profile.
                                        </li>
                                        <li>Ensure consistent NAP (Name, Address, Phone) information across all local listings.
                                        </li>
                                        <li>Fix broken links, 404 errors, and other technical issues.
                                        </li>

                                    </ul>
                                </div>


                                <h5>7. Analytics and Reporting

                                </h5>
                                <div>
                                    <ul>
                                        <li>Set up and monitor Google Analytics and Google Search Console.                                        .
                                        </li>
                                        <li>Provide regular reports on SEO performance, traffic, and ranking improvements.
                                        </li>
                                        <li>Use data to refine and adjust SEO strategies for continuous improvement.
                                        </li>

                                    </ul>
                                </div>


                            </div>
                        </div>

               


                        <div class="single-apply-jobs">
                            <div class="row align-items-center">
                                <div class="col-md-5">
                                    <div class="">
                                        <button class="btn btn-default mr-15">
                                            <a className="" href="/Contact"> Contact Us</a>
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </section>

        <Footer />
    </div>
};