import React, { useState, useEffect } from 'react';
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import LoImage from '../../imgs/logo.png';
import payImage from '../../imgs/Payment.jpg';
import emailjs from 'emailjs-com';
import { gapi } from 'gapi-script';


export const Contact = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });


  const CLIENT_ID = 'URUJ4zGjqhmzN4x8j';
  const API_KEY = 'YOUR_API_KEY';
  const SCOPES = 'https://www.googleapis.com/auth/gmail.send';


  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState('');
  const [gapiLoaded, setGapiLoaded] = useState(false);

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        scope: SCOPES,
        discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest'],
      }).then(() => {
        setGapiLoaded(true);
      });
    };

    gapi.load('client:auth2', initClient);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const validate = () => {
    let tempErrors = {};
    let isValid = true;

    if (formData.name.trim().length === 0) {
      tempErrors["name"] = "Name is required";
      isValid = false;
    }

    const emailPattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
    if (!emailPattern.test(formData.email)) {
      tempErrors["email"] = "Email is not valid";
      isValid = false;
    }

    if (formData.message.trim().length === 0) {
      tempErrors["message"] = "Message is required";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;


  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      emailjs.send(
        'service_asssgvh',  // Replace with your EmailJS service ID
        'template_nv53xfd', // Replace with your EmailJS template ID
        formData,
        'URUJ4zGjqhmzN4x8j'   // Replace with your EmailJS public key
      ).then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setStatus('Email sent successfully!');

        setFormData({
          name: '',
          email: '',
          message: ''
        });

      }).catch((err) => {
        console.log('FAILED...', err);
        setStatus('Failed to send email.');
      });
    }
  };




  return <div>
    <Header />

    <section class="section-box header">
      <div class="breadcrumb-cover bg-img-about breadcrumb-main">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <h2 class="mb-10">Contact Us</h2>
              <p class="font-lg color-text-paragraph-2">Contact Us</p>
            </div>
            <div class="col-lg-6 text-md-end">
              <ul class="breadcrumbs  mt-40 ">
                <li><a href="../index.htm"><span class="fi-rr-home icon-home"></span>Home</a></li>
                <li>Contact Us</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <br />

    <div class="row">
      <div class="container">
        <div class="col-lg-12 text-center">
          <h2 class="mb-10 headingmobile">Contact Us</h2>
        </div>

        <div class="box-info-contact">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-12">
              <a href="">
                <img className='contactlogoimg' src={LoImage} alt="Amigowebster - Low cost website designing company" />
              </a>
              <div class="font-sm color-text-paragraph">
                Corporate Office
                <br />
                No 9&amp;10 S4 Second Floor
                Sudarkodi Apts
                <br />
                Mainkandan Nagar 1st Street<br />
                Phone : +91 - 9445332233
                <br />
                Email : balaji@amigowebster.com
                <br/> <br/>

                Branch Office
                <br />
                New Vision Township<br />
                Plot No : 6  Anna Main Road<br />
                Vengabakkam<br />
                Phone : +91 - 9445332233
                <br/> <br/>



                <p> <img className='contactlogoimg' src={payImage} alt="Amigowebster - Low cost website designing company" /> </p>
              </div>

            </div>
            <div class="col-1"></div>
            <div class="col-lg-8 col-md-6 sm-12">
              <iframe width="100%" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.440159947267!2d80.17887401081668!3d12.943662415437156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xac145f8b83bf887b%3A0xe53a61381d821b68!2sAMIGOWEBSTER.in!5e0!3m2!1sen!2sin!4v1711344922164!5m2!1sen!2sin" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>

      </div>
    </div>





    <br />

    <div class="row">
      <div class="container">
        <div class="col-lg-12 text-center">
          <h2 class="mb-10 headingmobile">Contact Form</h2>
        </div>
      </div>
    </div>


    <div className='container'>
      <div className='col-md-12'>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <span>{errors.name}</span>}
          </div>
          <div>
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <span>{errors.email}</span>}
          </div>
          <div>
            <label>Message:</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
            />
            {errors.message && <span>{errors.message}</span>}
          </div>
          <button type="submit">Submit</button>
          {status && <p>{status}</p>}
        </form>
      </div>
    </div>

    <Footer />
  </div>
};


