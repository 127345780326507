import React from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { Link } from "react-router-dom";

export const Refundpolicy = () => {

    return <div>
        <Header />

        <section class="section-box header">
            <div class="breadcrumb-cover bg-img-about breadcrumb-main">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <h2 class="mb-10">Refund policy</h2>
                            <p class="font-lg color-text-paragraph-2">Refund policy</p>
                        </div>
                        <div class="col-lg-6 text-md-end">
                            <ul class="breadcrumbs  mt-40 ">
                                <li><a href="../index.htm"><span class="fi-rr-home icon-home"></span>Home</a></li>
                                <li>Refund policy</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-box mt-50 job-detail-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-12 col-lg-8">

                        <div class="content-single">
                            <div class="ck-content">
                                <h5>Amigowebster Refund Policy
                                </h5>

                                <div>

                                    <h4>Effective Date: [1/1/2023]
                                    </h4>
                                    <p>We strive to provide the best possible service to our customers. If you are not satisfied with our services,
                                        please refer to the following refund policy:</p>

                                </div>
                                <h5>1. Eligibility for Refunds:
                                </h5>
                                <div>
                                    <p>Refunds can be requested if you are not satisfied with the service
                                        provided or if there were technical issues preventing the service from being delivered as
                                        promised..</p>
                                </div>

                                <h5>2. Time Frame:

                                </h5>
                                <div>
                                    <p>Refund requests must be made within 30 days from the date of service.</p>
                                </div>


                                <h5>3. Refund Process:

                                </h5>
                                <div>
                                    <p>To request a refund, please contact our customer support team at [email
                                        address] or [phone number] with your order details and the reason for your refund request. Our
                                        team will review your request and process it within 5-7 business days.</p>
                                </div>


                                <h5>4 Partial Refunds:


                                </h5>
                                <div>
                                    <p>In cases where only a portion of the service was unsatisfactory, we may offer a
                                        partial refund proportional to the level of dissatisfaction.</p>

                                </div>


                                <h5>5. Dispute Resolution:
                                </h5>
                                <div>
                                    <p>If there is a disagreement regarding the refund, we will work with you to
                                        resolve the issue fairly and efficiently. Please reach out to our support team with any concerns.</p>
                                </div>


                                <h5>6. Contact Information:

                                </h5>
                                <div>
                                    <p> For any questions or assistance with the refund process, please contact us
                                        at:
                                        o Email:balaji@amigowebster.com
                                        o Phone: +91 9445332233
                                        We value your business and aim to ensure your satisfaction with our services. Thank you for choosing
                                        Amigowebster.</p>
                                </div>


                            </div>
                        </div>




                        <div class="single-apply-jobs">
                            <div class="row align-items-center">
                                <div class="col-md-5">
                                    <div class="">
                                        <button class="btn btn-default mr-15">
                                            <Link to="/Contact">Contact</Link>
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <Footer />
    </div>
};